import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import { Container, Grid } from '@material-ui/core';

import { GeneralHero } from '../components/General/GeneralHero';
import { Resources } from '../components/General/Resources';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { SEO } from '../components/SEO';

import LocaleContext from '../context/LocaleContext';
import Form from '../components/ContactForm/Form';

const NewsletterPage = ({ data: { newsletter }, location }) => {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const {
    resourceTitle,
    resources,
    _rawResourceBody,
    hero,
    contactForm,
    pardotUrl,
    metaTitle,
    metaDescription,
  } = newsletter;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />

      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      <SectionWhite>
        <Container>
          <Grid container justifyContent='center'>
            <Form
              pardotUrl={pardotUrl}
              contactForm={contactForm}
              location={location}
              slideUp
              fullWidthForm
            />
          </Grid>
        </Container>
      </SectionWhite>

      <SectionWhite top zIndex={3}>
        {/* <SectionWaveDefault top='0px' fillColorBorder='#FFF' /> */}
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>
    </>
  );
};

export default NewsletterPage;

export const query = graphql`
  query ($slug: String!) {
    newsletter: sanityNewsletter(slug: { current: { eq: $slug } }) {
      metaTitle
      metaDescription
      _type
      id
      slug {
        current
      }
      language
      hero {
        softwareLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        showEmailForm
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
    }
  }
`;
